h4, h5, h6,
h1, h2, h3 {margin: 0;}
ul, ol {margin: 0;}
p {margin: 0;}

html, body{
	font-family: 'Roboto', sans-serif;
    font-size: 100%;
    overflow-x: hidden;
    background: gray 0px 0px;
	background-size:cover;
}
body a{
	transition: 0.5s all ease;
	-webkit-transition: 0.5s all ease;
	-moz-transition: 0.5s all ease;
	-o-transition: 0.5s all ease;
	-ms-transition: 0.5s all ease;
	text-decoration:none;
}
h1,h2,h3,h4,h5,h6{
	margin:0;			   
}
p{
	margin:0;
}
ul,label{
	margin:0;
	padding:0;
}
body a:hover{
	text-decoration:none;
}
.login-title{
    color: black !important;
}
	/*sidebar navigation*/
#sidebar {
    width:240px;
    height:100%;
    position:fixed;
    background:rgba(52, 48, 48, 0.78);
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
    -o-transition:all .3s ease-in-out;
    transition:all .3s ease-in-out;
}
.side-bar-item{
    cursor:pointer
}
#sidebar ul li {
    position: relative;
}
.leftside-navigation,.right-stat-bar{
    height: 100%;
}
.right-stat-bar ul {
    list-style-type: none;
    padding-left: 0;
}
/*LEFT NAVIGATION ICON*/
.dcjq-icon {
    height:17px;
    width:17px;
    display:inline-block;
    background:red top;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
    position:absolute;
    right:10px;
}
.active .dcjq-icon {
    background:red bottom;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
}
.right-side-accordion .dcjq-icon {
    height:17px;
    width:17px;
    display:inline-block;
    background:red top;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
    position:absolute;
    right:10px;
}
.right-side-accordion .active .dcjq-icon {
    background:red bottom;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius: 3px;
}
.right-side-accordion li:nth-child(2) ul li .prog-row {
    border: none;
}
/*---*/
.nav-collapse.collapse {
    display:inline;
}
ul.sidebar-menu,ul.sidebar-menu li ul.sub {
    margin:-2px 0 0;
    padding:0;
}
ul.sidebar-menu {
    padding-top:80px;

}
#sidebar>ul>li>ul.sub {
    display:none;
}
#sidebar .sub-menu>.sub li a {
    padding-left:46px;
}
#sidebar>ul>li.active>ul.sub,#sidebar>ul>li>ul.sub>li>a {
    display:block;
}
ul.sidebar-menu li ul.sub li {
    background:rgba(52, 48, 48, 0);
    margin-bottom:0;
    margin-left:0;
    margin-right:0;
}
ul.sidebar-menu li ul.sub li a {
    font-size:12px;
    padding-top:13px;
    padding-bottom:13px;
    -webkit-transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
    -ms-transition:all 0.3s ease;
    transition:all 0.3s ease;
    color:#fff;
}
ul.sidebar-menu li ul.sub li a:hover,ul.sidebar-menu li ul.sub li.active a {
    color:#fff;
    -webkit-transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
    -ms-transition:all 0.3s ease;
    transition:all 0.3s ease;
    display:block;
    background:rgba(40, 40, 46, 0.28);
}
ul.sidebar-menu li {
    border-bottom:1px solid rgba(255,255,255,0.05);
}
ul.sidebar-menu li.sub-menu {
    line-height:15px;
}
ul.sidebar-menu ul.sub li {
    border-bottom:none;
}
ul.sidebar-menu li a span {
    display:inline-block;
}
ul.sidebar-menu li a {
    color:#fff;
    text-decoration:none;
    display:block;
    padding:18px 0 18px 25px;
    font-size:12px;
    outline:none;
    -webkit-transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
    -ms-transition:all 0.3s ease;
    transition:all 0.3s ease;
}
ul.sidebar-menu li a.active, ul.sidebar-menu li a:hover, ul.sidebar-menu li a:focus {
    background: rgba(40, 40, 46, 0.28);
    color: #fff;
    display: block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
ul.sidebar-menu li a i {
    font-size:15px;
    padding-right:6px;
}
ul.sidebar-menu li a:hover i,ul.sidebar-menu li a:focus i {
    color:#fff;
}
ul.sidebar-menu li a.active i {
    color:#fff;
}
.mail-info,.mail-info:hover {
    margin:-3px 6px 0 0;
    font-size: 11px;
}
/*main content*/
#main-content {
    margin-left:240px;
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
    -o-transition:all .3s ease-in-out;
    transition:all .3s ease-in-out;
}
.footer {
    background: unset;
    padding: 20px;
}
.footer p {
    color: #fff;
    letter-spacing: 1px;
}
.footer p a {
    color: #744e7d;
}
.footer p a:hover {
    color: #fff;
}
.header {
    left:0;
    right:0;
    z-index:1002;
    background:rgb(240, 188, 180);
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
    -o-transition:all .3s ease-in-out;
    transition:all .3s ease-in-out;
}
.merge-header {
    margin-right:240px;
}
.fixed-top {
    position:fixed;
    box-shadow:1px 0 3px rgba(0,0,0,.15);
}
.wrapper {
    display:inline-block;
    margin-top:80px;
    padding:15px;
    width:100%;
}
.brand {
    background:#8b5c7e;
    float:left;
    width:240px;
    height:80px;
    position:relative;
}
a.logo {
    font-size:32px;
    color:#fff;
    float:left;
    margin:20px 0 0 25px;
    text-transform:uppercase;
}
a.logo:hover,a.logo:focus {
    text-decoration:none;
    outline:none;
}
a.logo span {
    color: #FF6C60;
}
/*notification*/
#top_menu .nav>li,ul.top-menu>li {
    float:left;
}
.notify-row {
    float:left;
    margin-top:23px;
    margin-left:25px;
}
ul.top-menu {
    margin-right:15px;
    margin-top: 0;
}
ul.top-menu > li > a {
    color: #fff;
    font-size: 16px;
    background: #8b5c7e;
    padding: 4px 8px;
    margin-right: 15px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    padding-right: 8px !important;
}
ul.top-menu>li>a:hover,ul.top-menu>li>a:focus {
    background:#fa9fa4;
    text-decoration:none;
    color:#fff !important;
    padding-right:8px !important;
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
     background:#fa9fa4 !important;
    color:#fff !important;
}
.notify-row .badge {
    position:absolute;
    right:-10px;
    top:-10px;
    z-index:100;
}
.dropdown-menu.extended {
    max-width:320px !important;
    min-width:160px !important;
    top:42px;
    width:300px !important;
    padding:0 10px;
    box-shadow:0 0px 5px rgba(0,0,0,0.1) !important;
    border-radius:5px;
    -webkit-border-radius:5px;
    background:#fff;
    border:none;
    left:-10px;
}
.notify-row .notification span.label {
    display:inline-block;
    height:21px;
    padding:5px;
    width:22px;
    font-size:12px;
    margin-right:10px;
}
.dropdown-menu.extended .alert-icon,.noti-info {
    float:left;
}
.noti-info {
    padding-left:10px;
    padding-top:6px;
    color:#414147;
}
.dropdown-menu.extended .alert {
    margin-bottom:10px;
}
.dropdown-menu.extended .alert-icon {
    border-radius:100%;
    display:inline-block;
    height:35px;
    width:35px;
}
.dropdown-menu.extended .alert-icon i {
    font-size:16px;
    width:35px;
    line-height:35px;
    height:35px;
}
.dropdown-menu.extended.inbox li a,.dropdown-menu.extended.tasks-bar li a {
    background:#f1f2f7;
    border-radius:5px;
    -webkit-border-radius:5px;
    padding:10px;
    margin-bottom:10px;
    float:left;
    width:100%;
}
.dropdown-menu.extended li p {
    margin:0;
    padding:10px 0;
    border-radius:0px;
    -webkit-border-radius:0px;
}
.dropdown-menu.extended li a {
    font-size:12px;
    list-style:none;
}
.dropdown-menu.extended.logout {
    padding:10px;
}
.dropdown-menu.extended.logout li a {
    padding:10px;
}
.dropdown-menu.extended li a:hover {
    color:#32323a;
}
.dropdown-menu.tasks-bar .task-info .desc {
    font-size:13px;
    font-weight:normal;
    float:left;
    width:80%;
}
.dropdown-menu.tasks-bar .task-info .desc h5 {
    color:#32323a;
    text-transform:uppercase;
    font-size:12px;
    font-weight:600;
    margin-bottom:5px;
    margin-top:0;
}
.dropdown-menu.tasks-bar .task-info .desc p {
    padding-top:0;
    color:#8f8f9b;
    font-weight:300;
}
.dropdown-menu.tasks-bar .task-info .percent {
    width:20%;
    float:right;
    font-size:13px;
    font-weight:600;
    padding-left:10px;
    line-height:normal;
}
.dropdown-menu.tasks-bar .progress {
    background:#fff;
}
.dropdown-menu.extended .progress {
    margin-bottom:0 !important;
    height:10px;
}
.dropdown-menu.inbox li a .photo img {
    border-radius:2px 2px 2px 2px;
    -webkit-border-radius:2px 2px 2px 2px;
    float:left;
    height:40px;
    margin-right:10px;
    width:40px;
}
.dropdown-menu.inbox li a .subject {
    display:block;
}
.dropdown-menu.inbox li a .subject .from {
    font-size:12px;
    font-weight:600;
}
.dropdown-menu.inbox li a .subject .time {
    font-size:11px;
    font-style:italic;
    font-weight:bold;
    position:absolute;
    right:20px;
}
.dropdown-menu.inbox li a .message {
    display:block !important;
    font-size:11px;
}
.top-nav {
    margin-top:20px;
}
.top-nav img {
    border-radius:50%;
    -webkit-border-radius:50%;
    width:33px;
}
.top-nav .icon-user i {
    height: 33px;
    width:33px;
    line-height: 33px;
    display: inline-block;
    font-size: 1.7em;
    padding-left: 10px;
}
.top-nav .icon-user .username {
    color: #555555;
    font-size: 13px;
    position: relative;
    top: -5px;
}
.top-nav .icon-user .caret {
    position: relative;
    top: -4px;
}
.top-nav ul.top-menu>li .dropdown-menu.logout {
    width:170px !important;
}
.top-nav li.dropdown .dropdown-menu {
    float:right;
    right:0;
    left:auto;
}
.dropdown-menu.extended.logout>li {
    float:left;
    width:100%;
}
.log-arrow-up {
    background:red;
    width:18px;
    height:10px;
    margin-top:-20px;
    float:right;
    margin-right:15px;
}
.dropdown-menu.extended.logout>li>a {
    border-bottom:none !important;
}
.full-width .dropdown-menu.extended.logout>li>a:hover {
    background:#F1F2F7 !important;
    color:#32323a !important;
}
.dropdown-menu.extended.logout>li>a:hover {
    background:#F1F2F7 !important;
    border-radius:5px;
}
.dropdown-menu.extended.logout>li>a:hover i {
    color:#ffa2a2;
}
.dropdown-menu.extended.logout>li>a i {
    font-size:17px;
}
.dropdown-menu.extended.logout>li>a>i {
    padding-right:10px;
}
.top-nav .username {
    font-size:13px;
    color:#fff;
}
.top-nav ul.top-menu>li>a {
    border-radius: 100px;
    -webkit-border-radius: 100px;
    padding: 0px;
    background: none;
    margin-right: 0;
    border: 1px solid #8b5c7e;
    background: #8b5c7e;
}
.top-nav ul.top-menu>li.language>a {
    margin-top:-2px;
    padding:4px 12px;
    line-height:20px;
}
.top-nav ul.top-menu>li.language>a img {
    border-radius:0;
    -webkit-border-radius:0;
    width:18px;
}
.top-nav ul.top-menu>li.language ul.dropdown-menu li img {
    border-radius:0;
    -webkit-border-radius:0;
    width:18px;
}
.top-nav ul.top-menu>li {
    margin-left:10px;
}
.top-nav ul.top-menu>li>a:hover,.top-nav ul.top-menu>li>a:focus {
    border:1px solid #fa9fa4;
    background:#fa9fa4 !important;
    border-radius:100px;
    -webkit-border-radius:100px;
}
.top-nav .dropdown-menu.extended.logout {
    top:50px;
}
.top-nav .nav .caret {
    border-bottom-color:#A4AABA;
    border-top-color:#A4AABA;
}
.top-nav ul.top-menu>li>a:hover .caret {
    border-bottom-color:#000;
    border-top-color: #000;
}
/*form*/
.position-center {
    width:62%;
    margin: 0 auto;
}
/*----*/
.notify-arrow {
    background:red;
    width:18px;
    height:10px;
    margin-top:0;
    opacity:0;
    position:absolute;
    left:16px;
    top:-20px;
    transition:all 0.25s ease 0s;
    z-index:10;
    margin-top:10px;
    opacity: 1;
}
/*search*/
.search {
    width: 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    border: 1px solid #8b5c7e;
    box-shadow: none;
    background: #8b5c7e 10px 8px;
    padding: 0 5px 0 30px;
    color: #fff;
    border-radius: 100px;
    -webkit-border-radius: 100px;
	cursor:pointer;
}
.search:focus {
    width:180px;
    border:1px solid #8b5c7e;
    box-shadow:none;
    -webkit-transition:all .3s ease;
    -moz-transition:all .3s ease;
    -ms-transition:all .3s ease;
    -o-transition:all .3s ease;
    transition:all .3s ease;
    color:#c8c8c8;
    font-weight: 300;
}
.todo-search:focus{
    width:100% !important;
}
/*--sidebar toggle---*/
.sidebar-toggle-box {
    float:left;
    margin-top:23px;
    margin-left:-15px;
    background:#8b5c7e;
    border-radius:50%;
    -webkit-border-radius:50%;
    width:32px;
    height:32px;
    position:absolute;
    right:-15px;
}
.sidebar-toggle-box .fa-bars {
    cursor:pointer;
    display:inline-block;
    font-size:15px;
    padding:8px 8px 8px 9px;
    color:#fff;
}
.sidebar-toggle-box:hover {
    background:#fa9fa4;
}
.hide-left-bar {
    margin-left:-240px !important;
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
    -o-transition:all .3s ease-in-out;
    transition:all .3s ease-in-out;
}
.open-right-bar {
    right:0px !important;
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
    -o-transition:all .3s ease-in-out;
    transition:all .3s ease-in-out;
}
.merge-left {
    margin-left:0px !important;
}
.hide-right-bar {
    margin-right:-240px !important;
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
    -o-transition:all .3s ease-in-out;
    transition:all .3s ease-in-out;
}
.toggle-right-box {
    float:left;
    background:#f6f6f6;
    border-radius:50%;
    -webkit-border-radius:50%;
    width:35px;
    height:35px;
}
.toggle-right-box:hover {
    background:#ffa2a2;
}
.toggle-right-box:hover .fa-bars {
    color:rgba(0,0,0,0.3);
}
.toggle-right-box .fa-bars {
    cursor:pointer;
    display:inline-block;
    font-size:15px;
    padding:10px;
    color: #bfbfc1;
}
/*--market--*/
.market-update-block {
    padding: 2em 2em;
    background: #999;
}
.market-update-block h3 {
    color: #fff;
    font-size: 2em;
}
.market-update-block h4 {
	font-size: 1.2em;
    color: #fff;
    margin: 0.3em 0em;
}
.market-update-block p {
    color: #fff;
    font-size: 0.8em;
    line-height: 1.8em;
}
.market-update-block.clr-block-1 {
    background: #53d769;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-2 {
    background:#fc3158;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-3 {
    background:#147efb;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-4 {
    background:#2a2727;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-1:hover {
    background: #8b5c7e;
   transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-2:hover {
    background: #8b5c7e;
   transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-3:hover {
    background:#8b5c7e;
    transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-block.clr-block-4:hover {
    background:#8b5c7e;
    transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
}
.market-update-right i.fa.fa-users {
    font-size: 3em;
    color:#fff;
   text-align: left;
}
.market-update-right i.fa.fa-eye {
     font-size: 3em;
    color:#fff;
   text-align: left;
}
.market-update-right i.fa.fa-usd {
     font-size:3em;
    color:#fff;
    text-align: left;
}
.market-update-right i.fa.fa-shopping-cart{
     font-size:3em;
    color:#fff;
    text-align: left;
}
.market-update-left {
    padding: 0px;
}
.market-update-right {
    padding-left: 0;
}
.market-updates {
    margin: 1.5em 0;
}
/*--market--*/
.panel-heading {
    position: relative;
    height: 57px;
    line-height: 57px;
    letter-spacing: 0.2px;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    padding: 0 16px;
    background:#ddede0;
   border-top-right-radius: 2px;
   border-top-left-radius: 2px; 
    text-transform: uppercase;
    text-align: center;
}
.panel {
    border: none ! important;
}
.agileinfo-grap {
    padding: 2em;
    background:rgb(238, 249, 240);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
header.agileits-box-header.clearfix h3 {
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    color:#000;
    text-align: center;
    margin-bottom: 1em;
}
.calendar-widget {
    background-color: #eef9f0;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
	padding: 2em;
}
span.panel-title{
	color:#000000;
	 font-size: 18px;
}

.agile-calendar-grid {
    border: 1px solid #c0dbc5;
}
.alert-info .alert-icon {
    background-color: #99cce3;
}
.alert-danger .alert-icon {
    background-color: #fcb1ae;
}
.alert-success .alert-icon {
    background-color: #98d7ad;
}
.alert-warning .alert-icon {
    background-color: #ffe699;
}
.alert-icon i {
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: #fff;
}
.alert-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.notification-sender {
    color: #414147;
}
.alert-success .notification-info a {
    color: #42b663;
}
.notification-info p {
    margin: 0px;
    color: #999;
    font-size: 12px;
}
.notification-meta {
    margin-bottom: 3px;
    padding-left: 0;
    list-style: none;
}
.notification-info {
    margin-left: 56px;
    margin-top: -40px;
}
.notifications{
	background-color: #eef9f0;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    padding: 2em;
}
.alert-danger .notification-info a {
    color: #fb6f6b;
}
.alert-info .notification-info a {
    color: #45a2c9;
}
.alert {
    padding: 16.8px !important;
}
/*-- graphs --*/
.area-grids{
    border: 10px solid #d8d8d8;
    background: #FFF;
    padding: 1em;
}
.charts-right {
    padding-left: 0;
}
.chart-left {
    padding-right: 0;
}
.agile-bottom-right {
    padding-left: 0;
}
.agile-bottom-left {
    padding-right: 0;
}
#graph {
  width: 100%;
  margin: 20px auto 0 auto;
}
pre {
  height: 250px;
  overflow: auto;
}

div#graph1,div#graph4,div#graph5,div#graph6,div#graph7,div#graph8,div#graph9 {
    width: 100% !important;
    z-index: 1;
}
.agile-Updating-grids, .agile-bottom-grid, .agile-last-grid {
    background: #eef9f0;
    padding: 2em;
	border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.agile-bottom-grids {
    margin: 2em 0;
}
.agil-info-calendar {
    margin: 1.5em 0;
}
.area-grids-heading h3 {
    font-size: 20px;
    color: #000;
    text-align: center;
    margin-bottom: 1em;
    text-transform: uppercase;
}
/*-- graphs --*/
/*--statistics--*/
.stats-info ul li {
    margin-bottom: 1em;
    border-bottom:1px solid #e1ab91;
    padding-bottom: 18px;
    font-size: 0.9em;
    color: #555;
}
.table>thead>tr>th {
    border-bottom: 1px solid #e1ab91 ! important;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    border-bottom: 1px solid #e9e9e9 ! important;
}
.progress.progress-right {
    width: 25%;
    float: right;
    height: 8px;
    margin-bottom: 0;
}
.stats-info ul li.last {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0.5em;
}
.stats-info span.pull-right {
    font-size: 0.7em;
    margin-left: 11px;
    line-height: 2em;
}
.table.stats-table {
    margin-bottom: 0;
}
.stats-table span.label{
    font-weight: 500;
}
.stats-table h5 {
    color: #4F52BA;
    font-size: 0.9em;
}
.stats-table h5.down {
    color: #D81B60;
}
.stats-table h5 i.fa {
    font-size: 1.2em;
    font-weight: 800;
    margin-left: 3px;
}
.stats-table thead tr th {
    color: #8b5c7e;
}
.stats-table td {
    font-size: 0.9em;
    color: #555;
    padding: 11px !important;
}
/*--//statistics--*/
/*--Progress bars--*/
.progress {
    height: 10px;
    margin: 7px 0;
    overflow: hidden;
    background: #e1e1e1;
    z-index: 1;
    cursor: pointer;
}
.task-info .percentage{
	float:right;
	height:inherit;
	line-height:inherit;
}
.task-desc{
	font-size:12px;
}
.wrapper-dropdown-3 .dropdown li a:hover span.task-desc {
	color:#65cea7;
}
.progress .bar {
		z-index: 2;
		height:15px; 
		font-size: 12px;
		color: white;
		text-align: center;
		float:left;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		-ms-box-sizing: content-box;
		box-sizing: content-box;
		-webkit-transition: width 0.6s ease;
		  -moz-transition: width 0.6s ease;
		  -o-transition: width 0.6s ease;
		  transition: width 0.6s ease;
	}
.progress-striped .yellow{
	background:#f0ad4e;
} 
.progress-striped .green{
	background:#5cb85c;
} 
.progress-striped .light-blue{
	background:#4F52BA;
} 
.progress-striped .red{
	background:#d9534f;
} 
.progress-striped .blue{
	background:#428bca;
} 
.progress-striped .orange {
	background:#e94e02;
}
.progress-striped .bar {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress.active .bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
/*--Progress bars--*/
.stats-info-agileits {
    background: #eef9f0;
    padding: 2em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.agileits-w3layouts-stats {
    margin: 1.5em 0;
}
h4.title {
    font-size: 20px;
    color: #000;
    text-align: center;
    margin-bottom: 2em;
    text-transform: uppercase;
}
.stats-last-agile {
    background: #eef9f0;
    padding: 2em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.w3-agile-google_map{
    background: #eef9f0;
    padding: 2em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.w3-agile-map-left iframe{
		width:100%;
		min-height:450px;
		border: 15px solid #ffffff;
		outline:none;
}
.w3-agile-map-right iframe{
		width:100%;
		min-height:450px;
		border: 15px solid #ffffff;
		outline:none;
}
/*button*/
.btn-row {
    margin-bottom: 10px;
}
.typo-agile {
    background: #eef9f0;
    padding: 2em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
/*-- icons --*/
.codes a {
    color: #999;
}
.icon-box {
    padding: 8px 15px;
   background: rgb(218, 223, 219);
    margin: 1em 0 1em 0;
    border: 5px solid #eef9f0;
    text-align: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 13px;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -moz-transition: 0.5s all;
    cursor: pointer;
} 
.icon-box:hover {
    background: #000;
	transition:0.5s all;
	-webkit-transition:0.5s all;
	-o-transition:0.5s all;
	-ms-transition:0.5s all;
	-moz-transition:0.5s all;
}
.icon-box:hover i.fa {
	color:#fff !important;
}
.icon-box:hover a.agile-icon {
	color:#fff !important;
}
.codes .bs-glyphicons li {
    float: left;
    width: 12.5%;
    height: 115px;
    padding: 10px; 
    line-height: 1.4;
    text-align: center;  
    font-size: 12px;
    list-style-type: none;	
}
.codes .bs-glyphicons .glyphicon {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 24px;
}
.codes .glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	color: #777;
} 
.codes .bs-glyphicons .glyphicon-class {
    display: block;
    text-align: center;
    word-wrap: break-word;
}
h3.icon-subheading {
    font-size: 28px;
    color: #b64d99 !important;
    margin: 30px 0 15px;
    letter-spacing: 2px;
    border: none;
    display: block;
}
h3.agileits-icons-title {
    text-align: center;
    font-size: 33px;
    color: #222222;
    font-weight: 600;
    letter-spacing: 2px;
}
.icons a {
    color: #000000;
}
.icon-box i {
    margin-right: 10px !important;
    font-size: 20px !important;
    color: #000000!important;
}
.bs-glyphicons li {
    float: left;
    width: 18%;
    height: 115px;
    padding: 10px;
    line-height: 1.4;
    text-align: center;
    font-size: 12px;
    list-style-type: none;
    background:rgba(149, 149, 149, 0.18);
    margin: 1%;
}
.bs-glyphicons .glyphicon {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 24px;
	color: #282a2b;
}
.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	color: #777;
} 
.bs-glyphicons .glyphicon-class {
    display: block;
    text-align: center;
    word-wrap: break-word;
}
@media (max-width:1080px){
.icon-box {
    width: 33.33%;
}
}
@media (max-width:991px){
	h3.agileits-icons-title {
		font-size: 28px;
	}
	h3.icon-subheading {
		font-size: 22px;
	}
}
@media (max-width:768px){
	h3.agileits-icons-title {
		font-size: 28px;
	}
	h3.icon-subheading {
		font-size: 25px;
	}
	.row {
		margin-right: 0;
		margin-left: 0;
	}
	.icon-box {
    margin: 0;
    width: 100%;
}
}
@media (max-width: 640px){
	.icon-box {
		float: left;
		width: 50%;
	}
}
@media (max-width: 480px){
	.bs-glyphicons li {
		width: 31%;
	}
}
@media (max-width: 414px){
	h3.agileits-icons-title {
		font-size: 23px;
	}
	h3.icon-subheading {
		font-size: 18px;
	}
	.bs-glyphicons li {
		width: 31.33%;
	}
}
@media (max-width: 384px){
	.icon-box {
		float: none;
		width: 100%;
	}
}
/*-- //icons --*/
.w3_wthree_agileits_icons.main-grid-border {
    padding: 5em 0;
}
/*--Typography--*/
.well {
    font-weight: 300;
    font-size: 14px;
}
.list-group-item {
    font-weight: 300;
    font-size: 14px;
}
li.list-group-item1 {
    font-size: 14px;
    font-weight: 300;
}
.typo p {
    margin: 0;
    font-size: 14px;
    font-weight: 300;
}
.show-grid [class^=col-] {
    background: #fff;
	text-align: center;
	margin-bottom: 10px;
	line-height: 2em;
	border: 10px solid #f0f0f0;
}
.show-grid [class*="col-"]:hover {
	background: #e0e0e0;
}
.grid_3{
	margin-bottom:2em;
}
.xs h3, h3.m_1{
	color:#000;
	font-size:1.7em;
	font-weight:300;
	margin-bottom: 1em;
}
.grid_3 p{
	color: #999;
	font-size: 0.85em;
	margin-bottom: 1em;
	font-weight: 300;
}
.grid_4{
	background:none;
	
}
.label {
	font-weight: 300 !important;
	border-radius:4px;
}  
.grid_5{
	background:none;
	padding:2em 0;
}
.grid_5 h3, .grid_5 h2, .grid_5 h1, .grid_5 h4, .grid_5 h5, h3.hdg, h3.bars {
    margin-bottom: 1em;
    color: #b64d99;
    font-size: 26px;
    letter-spacing: 2px;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
	border-top: none !important;
}
.tab-content > .active {
	display: block;
	visibility: visible;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
	z-index: 0;
}
.badge-primary {
	background-color: #03a9f4;
}
.badge-success {
	background-color: #fb5710;
}
.badge-warning {
	background-color: #ffc107;
}
.badge-danger {
	background-color: #e51c23;
}
.grid_3 p{
	line-height: 2em;
	color: #888;
	font-size: 0.9em;
	margin-bottom: 1em;
	font-weight: 300;
}
.bs-docs-example {
	margin: 1em 0;
}
section#tables  p {
	margin-top: 1em;
}
.tab-container .tab-content {
	border-radius: 0 2px 2px 2px;
	border: 1px solid #e0e0e0;
	padding: 16px;
	background-color: #ffffff;
}
.table td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
	padding: 15px!important;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
	font-size: 0.9em;
	color: #999;
	border-top: none !important;
}
.tab-content > .active {
	display: block;
	visibility: visible;
}
.label {
	font-weight: 300 !important;
}
.label {
	padding: 4px 6px;
	border: none;
	text-shadow: none;
}
.alert {
	font-size: 0.85em;
}
h1.t-button,h2.t-button,h3.t-button,h4.t-button,h5.t-button {
	line-height:2em;
	margin-top:0.5em;
	margin-bottom: 0.5em;
}
li.list-group-item1 {
	line-height: 2.5em;
}
.input-group {
	margin-bottom: 20px;
}
.in-gp-tl{
	padding:0;
}
.in-gp-tb{
	padding-right:0;
}
.list-group {
	margin-bottom: 48px;
}
ol {
	margin-bottom: 44px;
}
h2.typoh2{
    margin: 0 0 10px;
}
@media (max-width:768px){
.grid_5 {
	padding: 0 0 1em;
}
.grid_3 {
	margin-bottom: 0em;
}
}
@media (max-width:640px){
h1, .h1, h2, .h2, h3, .h3 {
	margin-top: 0px;
	margin-bottom: 0px;
}
.grid_5 h3, .grid_5 h2, .grid_5 h1, .grid_5 h4, .grid_5 h5, h3.hdg, h3.bars {
	margin-bottom: .5em;
}
.progress {
	height: 10px;
	margin-bottom: 10px;
}
ol.breadcrumb li,.grid_3 p,ul.list-group li,li.list-group-item1 {
	font-size: 14px;
}
.breadcrumb {
	margin-bottom: 25px;
}
.well {
	font-size: 14px;
	margin-bottom: 10px;
}
h2.typoh2 {
	font-size: 1.5em;
}
.label {
	font-size: 60%;
}
.in-gp-tl {
	padding: 0 1em;
}
.in-gp-tb {
	padding-right: 1em;
}
}
@media (max-width:480px){
.grid_5 h3, .grid_5 h2, .grid_5 h1, .grid_5 h4, .grid_5 h5, h3.hdg, h3.bars {
	font-size: 1.2em;
}
.table h1 {
	font-size: 26px;
}
.table h2 {
	font-size: 23px;
}
.table h3 {
	font-size: 20px;
}
.label {
	font-size: 53%;
}
.alert,p {
	font-size: 14px;
}
.pagination {
	margin: 20px 0 0px;
}
.grid_3.grid_4.w3layouts {
	margin-top: 25px;
}
}
@media (max-width: 320px){
.grid_4 {
	margin-top: 18px;
}
h3.title {
	font-size: 1.6em;
}
.alert, p,ol.breadcrumb li, .grid_3 p,.well, ul.list-group li, li.list-group-item1,a.list-group-item {
	font-size: 13px;
}
.alert {
	padding: 10px;
	margin-bottom: 10px;
}
ul.pagination li a {
	font-size: 14px;
	padding: 5px 10px;
}
.list-group {
	margin-bottom: 10px;
}
.well {
	padding: 10px;
}
.nav > li > a {
	font-size: 14px;
}
}
/*-- //typography --*/
.typo {
    padding: 5em 0;
}
ul.bs-glyphicons-list {
    padding: 0;
}
.w3ls_head {
    font-size: 30px;
    color: #000000;
    margin-bottom: 2em;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}
.agile-grid{
	background: #eef9f0;
    padding: 2em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.wthree-font-awesome{
	background: #eef9f0;
    padding: 2em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.w3layouts-glyphicon{
	background: #eef9f0;
    padding: 2em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.table-agile-info{
	background: #eef9f0;
    padding: 2em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.form-w3layouts{
	background: #eef9f0;
    padding: 2em;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.panel-default>.panel-heading {
    color: #000000 ! important;
    background-color: #ddede0 ! important;
    border-color: #ddede0 ! important;
    font-size: 20px;
}
p.hd-title {
    color: #b64d99;
    font-size: 20px;
    margin-bottom: 1em;
}
.bg-success {
    color: #c6efd0;
    background-color: #27c24c;
}
.bg-info {
    color: #dcf2f8;
    background-color: #23b7e5;
}
.bg-info {
    color: #dcf2f8;
    background-color: #23b7e5;
}
.bg-danger {
    color: #ffffff;
    background-color: #f05050;
}
.text-success {
    color: #27c24c;
}
.text-warning {
    color: #fad733;
}
.text-danger {
    color: #f05050;
}
.btn-default {
    color: #58666e !important;
    background-color: #fcfdfd;
    background-color: #fff;
    border-color: #dee5e7;
    border-bottom-color: #d8e1e3;
    -webkit-box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
    box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
}
select.input-sm {
    height: 30px;
    line-height: 30px;
}
.w-sm {
    width: 150px;
}
.inline {
    display: inline-block !important;
}
.v-middle {
    vertical-align: middle !important;
}
.m-b-xs {
    margin-bottom: 5px;
}
#upload{
    font-family:'PT Sans Narrow', sans-serif;
    background-color:#fff;
    /*background-image:-webkit-linear-gradient(top, #373a3d, #313437);*/
    /*background-image:-moz-linear-gradient(top, #373a3d, #313437);*/
    /*background-image:linear-gradient(top, #373a3d, #313437);*/
    width:100%;
    padding:30px;
    border-radius:3px;
    margin:0px auto 100px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);*/
}
#drop{
    background-color: #fff;
    padding: 100px 50px;
    margin-bottom: 30px;
    border: 5px dashed #F1F2F7;
    border-radius: 3px;
    /*border-image: url('../img/border-image.png') 25 repeat;*/
    text-align: center;
    text-transform: uppercase;
    font-size:16px;
    font-weight:bold;
    color:#7f858a;
}
#drop a{
    background-color:#8b5c7e;
    padding:12px 26px;
    color:#fff;
    font-size:14px;
    border-radius:2px;
    cursor:pointer;
    display:inline-block;
    margin-top:12px;
    line-height:1;
}
#drop a:hover{
    background-color:#dfa7a6;
}
#drop input{
    display:none;
}
#upload ul{
    list-style:none;
    margin:0;
    /*border-top:1px solid #2b2e31;*/
    /*border-bottom:1px solid #3d4043;*/
}
#upload ul li{
    background-color:#F1F2F7;
    /*background-image:-webkit-linear-gradient(top, #333639, #303335);*/
    /*background-image:-moz-linear-gradient(top, #333639, #303335);*/
    /*background-image:linear-gradient(top, #333639, #303335);*/
    /*border-top:1px solid #3d4043;*/
    /*border-bottom:1px solid #2b2e31;*/
    padding:15px;
    height: 80px;
    position: relative;
}
#upload ul li input{
    display: none;
}
#upload ul li p{
    width: 144px;
    overflow: hidden;
    white-space: nowrap;
    color: #32323a;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    top: 20px;
    left: 100px;
}
#upload ul li i{
    font-weight: normal;
    font-style:normal;
    color:#7f7f7f;
    display:block;
}
#upload ul li canvas{
    top: 15px;
    left: 32px;
    position: absolute;
}
#upload ul li span{
    width: 15px;
    height: 12px;
    background: red;
    position: absolute;
    top: 34px;
    right: 33px;
    cursor:pointer;
}
#upload ul li.working span{
    height: 16px;
    background-position: 0 -12px;
}
#upload ul li.error p{
    color: #8b5c7e;
}
#tzine-download{
    opacity:0.9;
    background-color:#257691;
    font-size:11px;
    text-align:center;
    text-transform:uppercase;
    width:150px;
    height:28px;
    line-height:28px;
    text-decoration:none !important;
    display: inline-block;
    border-radius: 2px;
    color: #fff !important;
    font-weight: bold;
    box-shadow: 0 -1px 2px #1e5e74 inset;
    border-top:1px solid #26849c;
    text-shadow:1px 1px 1px #1e6176;
    margin-top:6px;
}
#tzine-download:hover{
    opacity:1;
}
#tzine-actions{
    position:absolute;
    top:0;
    width:500px;
    right:50%;
    margin-right:-420px;
    text-align:right;
}
#tzine-actions iframe{
    display: inline-block;
    height: 21px;
    width: 95px;
    position: relative;
    float: left;
    margin-top: 11px;
}
.badge.bg-important {
    background: #ff6c60;
}
.badge.bg-warning {
    background: #FCB322;
}
/* mail inbox */
.mail-nav {
    margin:15px -15px 0 -15px;
}
.mail-nav li a {
    border-radius:0;
    -webkit-border-radius:0;
    border-top:1px solid #f2f3f6;
    padding:15px;
    border-right:3px solid rgba(0,0,0,0);
}
.mail-nav>li>a:hover,.mail-nav>li>a:focus {
    background:#fafafa;
    color:#8b5c7e;
    border-right:3px solid #8b5c7e;
}
.mail-nav>li.active>a,.mail-nav>li.active>a:hover,.mail-nav>li.active>a:focus {
    background:#fafafa;
    color:#8b5c7e;
    border-right:3px solid #8b5c7e;
}
.mail-nav>li+li {
    margin-left:0;
    margin-top:0px;
}
.mail-nav i {
    font-size:16px;
    margin-right:10px;
}
.inbox-notification {
    margin-top:-2px;
}
.user-head .inbox-avatar {
    width:65px;
    float:left;
}
.user-head .inbox-avatar img {
    border-radius:4px;
    -webkit-border-radius:4px;
}
.user-head .user-name {
    display:inline-block;
    margin:0 0 0 10px;
}
.user-head .user-name h5 {
    font-size:14px;
    margin-top:15px;
    margin-bottom:0;
    font-weight:300;
}
.user-head .user-name h5 a {
    color:#fff;
}
.user-head .user-name span a {
    font-size:12px;
    color:#87e2e7;
}
a.mail-dropdown {
    background:#80d3d9;
    padding:3px 5px;
    font-size:10px;
    color:#01a7b3;
    border-radius:2px;
    margin-top:20px;
}
.inbox-body {
    padding:20px;
}
.btn-compose {
    background:#f0bcb4;
    padding:12px 0;
    text-align:center;
    width:100%;
    color:#fff;
}
.btn-compose:hover,.btn-compose:focus {
    background:#1ca59e;
    color:#fff;
}
ul.inbox-nav {
    display:inline-block;
    width:100%;
    margin:0;
    padding:0;
}
.inbox-divider {
    border-bottom:1px solid #d5d8df;
}
.inbox-action {
    margin-top:50px;
}
.src-position {
    margin-top:-7px;
}
.mail-src-position {
    margin-top:-3px;
}
ul.labels-info {
    border-bottom:1px solid #f1f2f7;
    margin-bottom:10px;
}
ul.inbox-nav li {
    display:inline-block;
    line-height:45px;
    width:100%;
}
ul.inbox-nav li a {
    color:#6a6a6a;
    line-height:45px;
    width:100%;
    display:inline-block;
    padding:0 20px;
}
ul.inbox-nav li a:hover,ul.inbox-nav li.active a,ul.inbox-nav li a:focus {
    color:#6a6a6a;
    background:#d5d7de;
}
ul.inbox-nav li a i {
    padding-right:10px;
    font-size:16px;
    color:#6a6a6a;
}
ul.inbox-nav li a span.label {
    margin-top:13px;
}
ul.labels-info li h4 {
    padding-top:5px;
    color:#5c5c5e;
    font-size:15px;
    text-transform:uppercase;
}
ul.labels-info li {
    margin:0;
}
ul.labels-info li a {
    color:#6a6a6a;
    border-radius:0;
    padding-left:0;
    padding-right:0;
}
ul.labels-info li a:hover,ul.labels-info li a:focus {
    color:#6a6a6a;
    background:none;
}
ul.labels-info li a i {
    padding-right:10px;
}
.nav.nav-pills.nav-stacked.labels-info p {
    margin-bottom:0;
    padding:0 22px;
    color:#9d9f9e;
    font-size:11px;
}
.inbox-head {
    padding:20px;
    background:#41cac0;
    color:#fff;
    border-radius:0 4px 0 0;
    -webkit-border-radius:0 4px 0 0;
    min-height:80px;
}
.inbox-head h3 {
    margin:0;
    display:inline-block;
    padding-top:6px;
    font-weight:300;
}
.inbox-head .sr-input {
    height:40px;
    border:none;
    box-shadow:none;
    padding:0 10px;
    float:left;
    border-radius:4px 0 0 4px;
    color:#8a8a8a;
}
.inbox-head .sr-btn {
    height:40px;
    border:none;
    background:#00a6b2;
    color:#fff;
    padding:0 20px;
    border-radius:0 4px 4px 0;
    -webkit-border-radius:0 4px 4px 0;
}
.wht-bg {
    background: #fff;
    padding: 20px;
}
.wht-bg h4 {
    font-size:24px;
    color:#a2a2a2;
    font-weight:300;
	text-align:left;
}
.table-inbox-wrap {
    margin:0 -15px;
}
.mail-checkbox {
    margin-right:10px;
}
.table-inbox {
    margin:0;
}
.table-inbox tr td {
    padding:12px !important;
}
.table-inbox tr td:first-child {
    width:5%;
}
.table-inbox tr td:first-child .icheckbox_minimal {
    margin-left:15px;
}
.table-inbox tr td:hover {
    cursor:pointer;
}
.table-inbox tr td .fa-star.inbox-started,.table-inbox tr td .fa-star:hover {
    color:#f78a09;
}
.table-inbox tr td .fa-star {
    color:#d5d5d5;
}
.table-inbox tr.unread td {
    font-weight:600;
    background:#f7f7f7;
}
.table-inbox tr.unread a {
    color: #32323a;
}
.table-inbox tr a {
    color: #767676;
}
ul.inbox-pagination {
    float:right;
    list-style-type: none;
}
ul.inbox-pagination li {
    float:left;
}
.pagination li a {
    color: #32323a;
}
.mail-option {
    display:inline-block;
    margin-bottom:10px;
    width:100%;
}
.mail-option .chk-all {
    float:left;
}
.mail-option .chk-all,.mail-option .btn-group {
    margin-right:5px;
}
.mail-option .chk-all,.mail-option .btn-group a.btn {
    border:1px solid #e7e7e7;
    padding:5px 10px;
    display:inline-block;
    background:#fcfcfc;
    color:#afafaf;
    border-radius:3px !important;
    -webkit-border-radius:3px !important;
}
.inbox-pagination a.np-btn {
    border:1px solid #e7e7e7;
    padding:5px 15px;
    display:inline-block;
    background:#fcfcfc;
    color:#afafaf;
    border-radius:3px !important;
    -webkit-border-radius:3px !important;
}
.mail-option .chk-all input[type=checkbox] {
    margin-top:0;
}
.mail-option .btn-group a.all {
    padding:0;
    border:none;
}
.inbox-pagination a.np-btn {
    margin-left:5px;
}
.inbox-pagination li span {
    display:inline-block;
    margin-top:7px;
    margin-right:5px;
}
.fileinput-button {
    border:1px solid #e6e6e6;
    background:#eeeeee;
}
.inbox-body .modal .modal-body input,.inbox-body .modal .modal-body textarea {
    border:1px solid #e6e6e6;
    box-shadow:none;
}
.btn-send,.btn-send:hover {
    background:#00A8B3;
    color:#fff;
}
.btn-send:hover {
    background:#009da7;
}
.modal-header h4.modal-title {
    font-weight:300;
    font-family:'Open Sans',sans-serif;
}
.modal-body label {
    font-weight:400;
    font-family: 'Open Sans',sans-serif;
}
.compose-mail {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 20px;
}
.compose-mail .compose-options {
    color: #979797;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 7px;
}
.compose-mail input, .compose-mail input:focus {
    border:none;
    padding: 0;
    width: 80%;
    float: left;
}
.compose-mail .form-group {
    border:1px solid #F2F3F6;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}
.compose-mail .form-group label {
    line-height: 34px;
    width: 10%;
    float: left;
    padding-left: 5px;
    margin-bottom: 0;
}
.compose-editor input {
    margin-top: 15px;
}
.compose-editor {
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
}
.compose-btn {
    float: left;
    margin-top: 6px;
}
.mail-header h4 {
    font-weight: 300;
}
.mail-sender, .attachment-mail {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    border-top:1px solid #EFF2F7 ;
    border-bottom:1px solid #EFF2F7 ;
    padding: 10px 0;
}
.mail-sender img {
    width: 30px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}
.mail-sender .date {
    line-height: 30px;
    margin-bottom: 0;
    text-align: right;
}
.view-mail a, .attachment-mail a:hover {
    color: #35bcb5;
}
.attachment-mail a{
    color: #32323a;
}
.attachment-mail ul li  {
    float: left;
    width: 200px;
    margin-right: 15px;
    margin-top: 15px;
    list-style: none;
}
.attachment-mail ul li a.atch-thumb img {
    width: 200px;
    height: 180px;
    margin-bottom: 10px;
}
.attachment-mail ul li a.name span {
    float: right;
    color: #767676;
}
.agile-info h3 {
    font-size: 2.5em;
    text-transform: uppercase;
    color: #8b5c7e;
    font-weight: 500;
    letter-spacing: 12px;
}
.agile-info h2 {
    font-size: 12em;
    color:#8b5c7e;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 20px;
}
.agile-info {
   padding:9em 2em 16.6em;
    background: rgba(240, 188, 180, 0.42);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    text-align: center;
}
.agile-info p {
    font-size: 1em;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 3em;
    margin-top: 2em;
}
.agile-info a {
    font-size: 1em;
    text-transform: uppercase;
    color: white;
    width: 18%;
    padding: 12px 0px;
    letter-spacing: 2px;
    display: inline-block;
    background: #f0bcb4;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
}
.agile-info a:hover {
    background: #8b5c7e;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
}
/*-- gallery --*/
.w3l-gallery-heading h3{
	text-align:right !important;
	color: #FF5722 !important;
}
.gallery-grid img {
    width: 100%;
    cursor: pointer;
}
.gallery-top-grids:nth-child(2),.gallery-top-grids:nth-child(3){
	margin-top:0;
}
.gallery-grids-left {
    padding: 10px;
}
.gallery-grid{
	position:relative;
	overflow: hidden;
}
.gallery-grid:hover .captn {
    bottom: 12%;
}
.captn {
    background:rgba(139, 92, 126, 0.73);
    padding: 2em;
    position: absolute;
    border: solid 1px #FFF;
    left: 6%;
    bottom: -100%;
    text-align: center;
    width: 340px;
    height: 200px;
    -webkit-transition: .5s all;
    transition: .5s all;
    -moz-transition: .5s all;
}
.captn h4 {
	font-size: 1.2em;
    color: #fff;
    margin: 2.5em 0 0 0;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
}
.captn p {
    margin: 0.5em 0 0 0;
    color: #FFFFFF;
    font-size: .9em;
}
/*-- //gallery --*/
.gallery {
    padding: 2em;
    background: rgb(238, 249, 240);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
/*-- gallery --*/
.w3layouts-main h2 {
	color: #8b5c7e;
    font-size: 29px;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-bottom: 15px;
	    text-align: center;
}
.w3layouts-main {
    width: 30%;
    margin:5em auto;
    background:rgba(171, 119, 157, 0.27);
    padding: 42px 35px;
}
/*--//header--*/
/*--login--*/
input.ggg{
    width: 100%;
    padding: 15px 0px 15px 15px;
    border: 1px solid #fff;
    outline: none;
    font-size: 14px;
    color: #fff;
    margin: 14px 0px;
	background: none;
}
.w3layouts-main span {
    font-size: 16px;
    color: #fff;
    float: left;
    width: 32%;
	margin-top: 8px;
}
.w3layouts-main h6 {
    font-size: 16px;
    float: right;
    width: 37%;
	color: #fff;
    letter-spacing: 1px;
	margin-top: 8px;
	text-decoration:underline;
}
.w3layouts-main a {
    color: #fff;
	transition:0.5s all;
	-webkit-transition:0.5s all;
	-o-transition:0.5s all;
	-moz-transition:0.5s all;
	-ms-transition:0.5s all;
}
.w3layouts-main a:hover{
	color:#f0bcb4;
	transition:0.5s all;
	-webkit-transition:0.5s all;
	-o-transition:0.5s all;
	-moz-transition:0.5s all;
	-ms-transition:0.5s all;
}
.w3layouts-main input[type="submit"] {
    padding: 12px 38px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: #f0bcb4;
    color: white;
    border: none;
    outline: none;
	display: table;
    cursor: pointer;
    margin: 45px auto 31px;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
}
.w3layouts-main input[type="submit"]:hover {
	background:#8b5c7e;
	transition:0.5s all;
	-webkit-transition:0.5s all;
	-o-transition:0.5s all;
	-moz-transition:0.5s all;
	-ms-transition:0.5s all;
}
.w3layouts-main p a {
    color: #f0bcb4;
    padding: 10px;
}
.w3layouts-main p a:hover {
    color: #fff;
}
.w3layouts-main p {
    font-size: 17px;
    color: #fff;
	text-align: center;
}
/*--//login--*/
h4 input[type="checkbox"] {
    margin-right: 10px;
}
.w3layouts-main h4{
    margin-right: 10px;
	color:#fff;
	font-weight:400;
	font-size:14px;
}
 /*--placeholder-color--*/
::-webkit-input-placeholder {
	color:#fff !important;
}
:-moz-placeholder { /* Firefox 18- */
	color:#fff !important; 
}
::-moz-placeholder {  /* Firefox 19+ */
	color:#fff !important;
}
:-ms-input-placeholder {  
	color:#fff !important;
}
/*--//placeholder-color--*/
.alert {
    margin-bottom: 23px !important;
}
div#chartContainer1,div#chartContainer2,div#chartContainer3,div#chartContainer4 {
    width: 100%;
    min-height: 500px;
}
div#chartContainer5,div#chartContainer6,div#chartContainer7,div#chartContainer8 {
    width: 100%;
    min-height: 500px;
}
.chart_agile_top {
    background-color: #eef9f0;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    padding: 2em;
    margin: 0 0 2em;
}
.floatcharts_w3layouts_top{
    background-color: #eef9f0;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    padding: 2em;
}
.map_wthree_top{
    background-color: #eef9f0;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    padding: 2em;
}
.vector-stat {
    height:150px;
    margin-top:20px;
}
canvas.canvasjs-chart-canvas {
}
.chart_agile_bottom{
	padding:20px;
	background:#fff;
}
.floatcharts_w3layouts_bottom{
	padding:20px;
	background:#fff;
}
.floatcharts_w3layouts_left {
    margin-bottom: 2em;
}
.w3-res-tb {
    padding: 2em 1em 0;
}
.pagination {
    margin: 0 !important;
}
div#graph10,div#graph11 {
    width: 100%;
}
/*-- responsive design --*/
@media (max-width: 1440px){
.w3layouts-main {
    width: 33%;
}
.captn {
    padding: 1em;
    width: 301px;
    height: 182px;
}
}
@media (max-width: 1366px){
.w3layouts-main {
    width: 35%;
}
.captn {
    width: 280px;
    height: 170px;
}
}
@media (max-width: 1280px){
.w3layouts-main {
    width: 38%;
}
.captn {
    width: 256px;
    height: 150px;
}
.market-update-block {
    padding: 1em 1em;
}
.alert {
    margin-bottom: 13px !important;
    padding: 12.8px !important;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
  width: 260px ! important;
}
}
@media (max-width: 1080px){
.w3layouts-main {
    width: 45%;
}
.agile-info {
    padding: 9.1em 2em;
}
.captn {
    width: 198px;
    height: 118px;
}
.captn h4 {
    margin: 1em 0 0 0;
}
.market-update-gd {
    float: left;
    width: 50%;
    margin: 1em 0;
}
.market-updates {
    margin: 0em 0;
}
.agile-calendar {
    width: 100%;
    margin-bottom: 1.5em;
}
.w3agile-notifications {
    width: 100%;
}
.agile-last-left {
    width: 100%;
}
.agile-last-left.agile-last-middle{
    width: 100%;
	margin:1.5em 0;
}
.agile-last-left.agile-last-right{
    width: 100%;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 650px !important;
}
.stats-info.widget {
    width: 100%;
    margin-bottom: 1.5em;
}
.stats-info.stats-last.widget-shadow {
    width: 100%;
}
.w-sm {
    width: 138px;
}
}
@media (max-width: 1024px){
.w3layouts-main {
    width: 47%;
}
.captn {
    width: 180px;
    height: 110px;
}
}
@media (max-width: 991px){
.captn {
    width: 171px;
    height: 102px;
}
.market-update-left {
    float: left;
    margin-left: 20px;
}
.market-update-right {
    float: left;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 600px !important;
}
.w-sm {
    width: 127px;
}
}
@media (max-width: 800px){
.w3layouts-main {
    width: 62%;
}
.gallery-grids-left {
    width: 100%;
}
.captn {
    width: 350px;
    height: 200px;
}
.captn h4 {
    margin: 3em 0 0 0;
}
.market-update-right {
    width: 100%;
    padding: 0;
    text-align: center;
}
.market-update-left {
    margin-left: 0;
    text-align: center;
    width: 100%;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 430px !important;
}
.table td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 7px!important;
}
.com-w3ls {
    width: 100%;
}
.mail-w3agile {
    width: 100%;
}
}
@media (max-width: 768px){
.agile-info {
    padding: 17em 2em;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 400px !important;
}
.table td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 6px!important;
}
#drop {
    padding: 100px 30px;
}
.table-agile-info {
    padding: 2em 2em 10.7em;
}
.position-center {
    width: 100%;
    margin: 0 auto;
}
#upload {
    margin: 0px auto 230px;
}
.w3ls-graph {
    padding: 0;
}
.w3layouts-main {
    margin: 14.3em auto;
}
.search:focus {
    width:170px;
}
.todo-search:focus{
    width:95% !important;
}
}
@media (max-width: 736px){
.w3layouts-main {
    width: 65%;
}
.agile-info {
    padding: 2em 2em;
}
.captn {
    width: 534px;
    height: 300px;
}
.captn h4 {
    margin: 6em 0 0 0;
}
.w3ls-graph {
    padding: 0;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 620px !important;
}
.table-agile-info {
    padding: 2em;
}
#upload {
    margin: 0px auto 50px;
}
.w3layouts-main {
    margin: 5em auto;
}
}
@media (max-width: 667px){
.w3layouts-main {
    width:72%;
}
.agile-info p {
    font-size: 0.9em;
    margin-bottom: 2em;
    margin-top: 2em;
}
.agile-info a {
    width: 26%;
}
.captn {
    width: 474px;
    height: 267px;
}
.captn h4 {
    margin: 5em 0 0 0;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 555px !important;
}
}
@media (max-width: 640px){
.w3layouts-main {
    width: 75%;
}
.agile-info {
    padding: 3.7em 2em;
}
.captn {
    width: 452px;
    height: 250px;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 525px !important;
}
}
@media (max-width: 600px){
.w3layouts-main {
    width: 80%;
}
.captn {
    width: 414px;
    height: 242px;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 480px !important;
}
}
@media (max-width: 568px){
.w3layouts-main {
    width: 84%;
}
.captn {
    width: 386px;
    height: 225px;
}
.captn h4 {
    margin: 4em 0 0 0;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 450px !important;
}
}
@media (max-width: 480px){
.w3layouts-main {
    width: 92%;
    margin: 3em auto;
    padding: 30px 20px;
}
.agile-info {
    padding: 7.1em 2em;
}
.agile-info a {
    width: 33%;
}
.captn {
    width: 308px;
    height: 180px;
}
.captn h4 {
    margin: 3em 0 0 0;
}
.w3-agile-map-left iframe {
    min-height: 300px;
}
.w3-agile-map-right iframe {
    min-height: 300px;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 332px !important;
}
.table-agile-info {
    padding: 1em;
}
.table td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 5px!important;
}
.form-w3layouts {
    padding: 1em;
}
}
@media (max-width: 414px){
.w3layouts-main h2 {
    font-size: 24px;
}
.w3layouts-main h6 {
    font-size: 14px;
    width: 45%;
}
.w3layouts-main span {
    font-size: 14px;
    width: 37%;
    margin-top: 2px;
}
.agile-info a {
    width: 40%;
}
.agile-info h2 {
    font-size: 9em;
}
.agile-info {
    padding: 5em 2em 11.3em;
}
.w3layouts-main {
    margin: 0em auto 11.4em;
    padding: 30px 20px;
}
.captn {
    width: 250px;
    height: 146px;
}
.captn h4 {
    margin: 2em 0 0 0;
}
.w3-agile-map-left,.w3-agile-map-right {
    padding: 0;
}
.market-update-gd {
    float: left;
    width: 100%;
}
.market-update-left {
    margin-left: 0;
    text-align: left;
    width: 63%;
}
.market-update-right {
    width: 26%;
    padding: 0;
    text-align: left;
}
.agileinfo-grap {
    padding: 1em;
}
.calendar-widget {
    padding: 1em;
}
.notifications {
    padding: 1em;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 300px !important;
}
.agile-Updating-grids, .agile-bottom-grid, .agile-last-grid {
    padding: 1em;
}
.table td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 6px!important;
}
.stats-info-agileits {
    padding: 1em;
}
.stats-last-agile {
    padding: 1em;
}
.w3layouts-glyphicon {
    padding: 1em;
}
.typo-agile {
    padding: 1em;
}
.agile-grid {
    padding: 1em;
}
.position-center {
    width: 92%;
}
#drop {
    padding: 50px 30px;
	margin-bottom:0;
}
.com-w3ls {
    padding: 0;
}
.mail-w3agile {
    padding: 0;
}
.wht-bg h4 {
    font-size: 16px;
}
.chart_agile_top {
    padding: 1em;
    margin: 0 0 1em;
}
.chart_agile_left,.chart_agile_right {
    padding: 0;
}
.floatcharts_w3layouts_top {
    padding: 1em;
}
.floatcharts_w3layouts_left {
    margin-bottom: 1em;
    padding: 0;
}
.vec-wthree{
	padding: 0;
}
}
@media (max-width: 384px){
.agile-info {
    padding: 5.9em 2em;
}
.w3layouts-main {
    margin: 0em auto 5.4em;
}
.gallery {
    padding: 1em;
}
.w3ls_head {
    margin-bottom: 0.5em;;
}
.table td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 3px!important;
}
.wht-bg h4 {
    font-size: 14px;
}
}
@media (max-width: 375px){
.agile-info {
    padding: 3em 2em 8.95em;
}
.captn {
    width: 244px;
    height: 146px;
}
.table td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 4px!important;
	    font-size: 0.75em;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 290px !important;
}
.wht-bg h4 {
    font-size: 13px;
}
}
@media (max-width: 320px){
.w3layouts-main {
    width: 95%;
	margin: 0em auto 2em;
}
.w3layouts-main h6 {
    font-size: 13px;
    width: 49%;
}
.w3layouts-main span {
    width: 45%;
}
.w3layouts-main input[type="submit"] {
    padding: 10px 26px;
    font-size: 16px;
	margin: 45px auto 18px;
}
.agile-info h2 {
    font-size: 7em;
}
.agile-info a {
    width: 55%;
	font-size:0.9em;
}
.agile-info {
    padding: 3em 2em 8.95em;
}
.captn {
    width: 196px;
    height: 114px;
}
.captn h4 {
    margin: 1em 0 0 0;
}
.w3-agile-google_map {
    padding: 1em;
}
.w3-agile-map-left iframe {
    min-height: 250px;
}
.w3-agile-map-right iframe {
    min-height: 250px;
}
.market-update-gd {
    padding: 0;
}
.market-update-gd {
    margin: 0.8em 0;
}
.market-update-right {
    width: 30%;
}
.panel-body {
    padding: 6px ! important;
}
.agileinfo-grap {
    margin-top: 0.8em;
}
.agile-calendar {
    margin-bottom: 1em;
    padding: 0;
}
.w3agile-notifications {
    padding: 0;
}
.agile-last-left {
    padding: 0;
}
div#graph1, div#graph4, div#graph5, div#graph6, div#graph7, div#graph8, div#graph9,div#graph10,div#graph11 {
    width: 250px !important;
}
.stats-info.widget {
    margin-bottom: 1em;
    padding: 0;
}
.stats-info.stats-last.widget-shadow {
    padding: 0;
}
.table td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 0px!important;
}
.agile-last-left.agile-last-middle {
    margin: 1em 0;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    font-size: 0.75em;
}
#drop {
    padding: 50px 16px;
}
.mail-nav {
    margin: 0;
}
.w3-res-tb {
    padding: 2em 0em 0;
}
.col-lg-12 {
    padding: 0;
}
.table-inbox {
    margin: 14px;
}
.table {
    width: 90%;
}
.wht-bg h4 {
    font-size: 16px;
	float:none;
	text-align:center;
}
.input-append {
    margin-top: 1em;
}
.search:focus {
    width:150px;
}
.todo-search:focus{
    width:90% !important;
}
.wthree-font-awesome {
    padding: 1em;
}
.floatcharts_w3layouts_bottom {
    padding: 0px;
}
.chart_agile_bottom {
    padding: 0;
}
}