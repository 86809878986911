@media print {
    body {
        visibility: hidden;
    }

    .toprint {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0;
        height: 100px;
    }

    .dshow{
        display: none;
    }

    .show{
        visibility: visible;
    }

}

.show{
    visibility: hidden;
}

.vanish{
    display: none;
}