/* Overall wrapper */
.monthly {
	background: #F3F3F5;
	color:#545454;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
}

/* Contains title & nav */
.monthly-header {
    position: relative;
    text-align: center;
    padding: 10px;
    background:#ddede0;
    height: 48px;
    box-sizing: border-box;
}
.monthly-header-title {
    font-size: 1em;
    text-transform: uppercase;
    color: #000000;
}
/* wrapper for left button to make the clickable area bigger */
.monthly-prev {
	position: absolute;
	top:0;
	left:0;
	width:50px;
	height:100%;
	opacity: .5;
}
.monthly-prev:hover {
	opacity: 1;
}
/* Left Arrow */
.monthly-prev:after{
	content:'';
	position: absolute;
	top:50%;
	left:50%;
	border-left:2px solid #222;
	border-bottom:2px solid #222;
	width:10px;
	height:10px;
	margin:-3px 0 0 -5px;
	-webkit-transform:rotate(45deg) ;
		-ms-transform:rotate(45deg) ;
	        transform:rotate(45deg) ;
}
/* wrapper for right button to make the clickable area bigger */
.monthly-next {
	position: absolute;
	top:0;
	right:0;
	width:50px;
	height:100%;
	opacity: .5;
}
.monthly-next:hover {
	opacity: 1;
}
/* Right Arrow */
.monthly-next:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-right: 2px solid #222;
    border-top: 2px solid #222;
    width: 10px;
    height: 10px;
    margin: -3px 0 0 -5px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Day of the week headings */
.monthly-day-title-wrap {
    display: table;
    width: 100.1%;
    background: #ddede0;
    border-bottom: 1px solid #c0dbc5;
    padding-bottom: 1.3em;
}
.monthly-day-title-wrap div {
    width: 14.28%!important;
    display: table-cell;
    box-sizing: border-box;
    position: relative;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
    color: #000000;
}

/* Calendar days wrapper */
.monthly-day-wrap {
	display:table;
	width:100.1%;
	overflow: hidden;
}
.monthly-week {
	display:table-row;
	width:100.1%;
}
/* Calendar Days */
.monthly-day, .monthly-day-blank {
	width: 14.28%!important;
    display: table-cell;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
    font-weight: bold;
    color: inherit;
        background: #ddede0;
    box-shadow: 0 0 0 1px #c0dbc5;
    -webkit-transition: .25s;
    transition: .25s;
    padding: 0;
    text-decoration: none;
}
.monthly-day-event {
    padding-top: 17px !important; 
}
/* Trick to make the days' width equal their height */
.monthly-day:before {
	content: "";
	display: block;
	padding-top: 100%;
	float: left;
}

/* Hover effect for non event calendar days */
.monthly-day-wrap > a:hover {
	background: #A1C2E3;
}

/* Days that are part of previous or next month */
.monthly-day-blank {
	background:#eef9f0;
}

/* Event calendar day number styles */
.monthly-day-event > .monthly-day-number {
    position: absolute;
    line-height: 24px;
    top: 2px;
    left: 2px;
    font-size: 13px;
    color: #000;
}

/* Non-Event calendar day number styles */
.monthly-day-pick {
}
.monthly-day-pick > .monthly-day-number {
    line-height: 1em;
    font-size: 11px;
    padding-top: 35%;
    color: #000;
}

.monthly-day-pick > .monthly-indicator-wrap {
	margin:0;
}

/* Days in the past in "picker" mode */
.monthly-past-day:after{
	content: '';
	width: 150%;
	height: 2px;
	-webkit-transform-origin: left top;
		-ms-transform-origin: left top;
	        transform-origin: left top;
	-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
	        transform: rotate(45deg);
	background: rgba(0, 0, 0, 0.1);
	position: absolute;
	left: 0;
	top: 0;
}
.monthly-past-day:hover {
	background: #fff!important;
}
/* Current day style */
.monthly-today .monthly-day-number {
    color: #ffffff;
    background: #000000;
    top: 1px;
    left: 1px;
    font-size: 13px;
    width: 176px;
    height: 156px;
    line-height: 24px;
}
.monthly-day-pick.monthly-today .monthly-day-number {
    padding: 0;
    margin: 21% 22% 0 12%;
}
/* Button to reset to current month */
.monthly-reset {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 2px solid #222;
    border-radius: 9px;
    position: relative;
    opacity: .5;
    margin-left: 5px;
    vertical-align: middle;
}
.monthly-reset:hover {
	opacity: 1;
}
/* Makes the little arrow on the reset button */
.monthly-reset:before {
    content: '';
    border: 3px solid transparent;
    border-left: 3px solid #fcb216;
    border-bottom: 3px solid #fcb216;
    position: absolute;
    left: 2px;
    top: 6px;
}
.monthly-reset:after {
    content: '';
    border: 3px solid transparent;
    border-left: 3px solid #222;
    border-bottom: 3px solid #222;
    position: absolute;
    left: 4px;
    top: 5px;
}
/* Button to return to month view */
.monthly-cal {
	display: inline-block;
	height:11px;
	width:13px;
	background:#222;
	position: relative;
	top:1px;
	margin-right:5px;
	opacity: .5;
}
.monthly-cal:hover {
	cursor: pointer;
	opacity: 1;
}
.monthly-cal:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 3px;
    border: 1px solid #000000;
    background: #000000;
    top: -2px;
    left: 2px;
}
.monthly-cal:after {
	content:'';
	position: absolute;
	width:2px;
	height: 3px;
	border:1px solid #000000;
	background:#000000;
	top:-2px;
	right:2px;
}
.monthly-cal div {
    background: #fcb216;
    height: 6px;
    width: 11px;
    position: absolute;
    top: 4px;
    left: 1px;
}


/* Wrapper around events */
.monthly-indicator-wrap {
	position: relative;
	text-align: center;
	line-height: 0;
	max-width: 20px;
	margin:0 auto;
	padding-top:40%;
}

/* Event indicator dots */
.monthly-day .monthly-event-indicator {
	display: inline-block;
	margin: 1px;
	width: 8px;
	height: 8px;
	border-radius: 6px;
	vertical-align: middle;
	border-radius: 6px;
	background:#7BA7CE;
	font-size:0;
	color:transparent;
}

.monthly-day .monthly-event-indicator:hover {
	cursor: pointer;
}
/* Listing of events under calendar */
.monthly-event-list {
	background: rgba(233, 235, 236, 0.9);
	overflow: auto;
	position: absolute;
	top: 40px;
	width: 100%;
	height: calc(100% - 42px);
	display: none;
	-webkit-transition: .25s;
	transition:.25s;
	-webkit-transform:scale(0);
		-ms-transform:scale(0);
	        transform:scale(0);
}

/* Days in Events List */
.monthly-list-item {
	position: relative;
	padding:10px 10px 5px 50px;
	display: none;
	border-top: 1px solid #D6D6D6;
	text-align: left;
}

.monthly-list-item:after{
    content:'No Events';
    padding:4px 10px;
    display:block;
    margin-bottom:5px;
 }

.monthly-event-list .monthly-today .monthly-event-list-date {
	color: #EA6565;
}

/* Events in Events List */
.monthly-event-list .listed-event {
	display: block;
	color:#fff;
	padding:4px 10px;
	border-radius:2px;
	margin-bottom: 5px;
}

.monthly-list-item a:link, .monthly-list-item a:visited {
	text-decoration: none;
}

.item-has-event {
	display: block;
}

.item-has-event:after{
    display:none!important;
}

.monthly-event-list-date {
	width:50px;
	position: absolute;
	left:0;
	top:13px;
	text-align: center;
	font-size: 12px;
    font-weight: bold;
    line-height: 1.2em;
}

.monthly-list-time-start,
.monthly-list-time-end {
	font-size:.8em;
	display: inline-block;
}
.monthly-list-time-start:not(:empty):after {
	content:' -';
	padding-right:5px;
}

/* Events List custom webkit scrollbar */

.monthly-event-list::-webkit-scrollbar {width: 9px;}

/* Track */
.monthly-event-list::-webkit-scrollbar-track {background: none;}

/* Handle */
.monthly-event-list::-webkit-scrollbar-thumb {
	background:#ccc;
	border:1px solid #E9EBEC;
	border-radius: 10px;
}
.monthly-event-list::-webkit-scrollbar-thumb:hover {background:#555;}

/* Increase font & spacing over larger size */
@media (min-width: 400px) {
	.monthly-day-number {
		top: 5px;
		left: 5px;
		font-size: 13px;
	}
}
/* Styles for large mode where text is revealed within events */
@media (min-width: 600px) {
	.monthly-day-event {
		padding-top: 20px;
	}
	.monthly-day-event:before {
		padding-top: 77%;
	}
	.monthly-day-event > .monthly-indicator-wrap {
		width:auto;
		max-width: none;
	}
	.monthly-indicator-wrap {
		padding:0;
	}
	.monthly-day .monthly-event-indicator {
		display: block;
		margin: 0 0 1px 0;
		width: auto;
		height:20px;
		font-size: 10px;
		padding: 4px;
		border-radius:0;
		overflow: hidden;
		text-overflow: ellipsis;
		color:#fff;
		text-shadow:0 0 2px rgba(0,0,0,.2);
		text-decoration: none;
		line-height: 1em;
		white-space: nowrap;
		box-sizing: border-box;
	}

}
